.interview_section {
  width: 980px;
  padding: 60px 80px 100px 80px;
  margin: auto;
}
@media screen and (max-width: 1023px) {
  .interview_section {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .interview_section {
    padding: 50px 20px 40px;
  }
}

.interview_head_text {
  position: absolute;
  bottom: 10%;
  left: calc(50% - 490px);
  font-size: 32px;
  position: absolute;
  font-family: Noto Serif JP, serif;
  text-shadow: 1px 1px 0 rgb(22 33 75 / 25%), -1px 1px 0 rgb(22 33 75 / 25%),
    1px -1px 0 rgb(22 33 75 / 25%), -1px -1px 0 rgb(22 33 75 / 25%);
  line-height: 1.625;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  font-weight: 300;
  letter-spacing: 0.05em;
}
.interview_head_text_sp {
  position: absolute;
  top: 5%;
  left: 5%;
  font-size: 22px;
  font-family: Noto Serif JP, serif;
  text-shadow: 1px 1px 0 rgb(22 33 75 / 25%), -1px 1px 0 rgb(22 33 75 / 25%),
    1px -1px 0 rgb(22 33 75 / 25%), -1px -1px 0 rgb(22 33 75 / 25%);
  line-height: 1.625;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  font-weight: 300;
  letter-spacing: 0.05em;
}

@media screen and (max-width: 1023px) {
  .interview_head_text {
    left: 80px;
  }
}

.customer_voice .title {
  font-size: 20px;
  font-weight: bold;
  color: #95742e;
}

.customer_voice .customer_name {
  font-size: 30px;
  font-weight: bold;
  color: #16214b;
  padding-bottom: 30px;
  border-bottom: #16214b solid 1px;
}

.customer_voice .read_text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.625;
}

.customer_voice .read_text pre {
  color: #16214b;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: Noto Serif JP, serif;
}

.customer_voice .customer_info {
  display: flex;
  background-color: #f8f5f0;
  padding: 30px;
}
.customer_voice .customer_info .customer_info_name {
  font-size: 18px;
  color: #16214b;
  margin-bottom: 1rem;
}
.customer_voice .customer_info .customer_info_text {
  font-size: 14px;
  color: #222;
  @apply leading-relaxed;
}
.customer_voice .customer_info .customer_info_image img {
  max-width: 260px;
}

.customer_voice .customer_info .customer_info_banner {
  display: block;
  padding: 0.25rem 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  position: relative;
  border-radius: 9999px;
  background-color: #95742e;
  width: 250px;
}
.customer_voice .customer_info .customer_info_banner .linkImg {
  content: '';
  position: absolute;
  margin-top: 5px;
  right: 25px;
}

@media screen and (max-width: 767px) {
  .customer_voice .title {
    font-size: 18px;
  }

  .customer_voice .customer_name {
    font-size: 23px;
  }

  .customer_voice .customer_info {
    display: block;
    background-color: #f8f5f0;
    padding: 20px 25px;
  }
  .customer_voice .customer_info .customer_info_name {
    font-size: 15px;
    @apply mb-2;
  }
  .customer_voice .customer_info .customer_info_text {
    font-size: 12px;
  }
  .customer_voice .customer_info .customer_info_image img {
    max-width: initial;
    width: 100%;
  }
}

.interview_contents {
  border-top: 1px solid #16214b;
  position: relative;
  margin-top: 5rem;
}

.interview_contents pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: Noto Serif JP, serif;
}

.interview_contents::before {
  display: block;
  content: '';
  line-height: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: -1px;
  width: 95%;
  border-top: 1px solid #fff;
}
.interview_contents .question {
  font-size: 22px;
  font-weight: bold;
  color: #16214b;
  font-size: 22px;
  font-weight: 700;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}
.interview_contents .question .number {
  display: inline-block;
  color: #95742e;
  margin-right: 1.5rem;
}
.interview_contents .answer {
  display: flex;
}
.interview_contents .contents_reverse {
  flex-flow: row-reverse;
}
.interview_contents .answer_text {
  @apply leading-relaxed;
}
.interview_contents .answer_text pre {
  font-family: Noto Serif JP, serif;
  word-wrap: break-word;
  white-space: pre-wrap;
  @apply leading-relaxed;
}
.interview_contents .answer_image img {
  max-width: 350px;
}

@media screen and (max-width: 767px) {
  .interview_contents {
    @apply mt-20;
  }
  .interview_contents::before {
    width: 80%;
  }
  .interview_contents .question {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: flex;
    flex-wrap: wrap;
  }
  .interview_contents .question .number {
    display: inline-block;
    color: #95742e;
    margin-right: 1.5rem;
  }
  .interview_contents .answer {
    display: block;
  }
  .interview_contents .contents_reverse {
    flex-flow: row-reverse;
  }
  .interview_contents .answer_text {
    @apply leading-relaxed;
  }
  .interview_contents .answer_text pre {
    font-family: serif;
    word-wrap: break-word;
    white-space: pre-wrap;
    @apply leading-relaxed;
  }
  .interview_contents .answer_image img {
    max-width: initial;
    width: 100%;
  }
}

.return_button {
  display: block;
  position: relative;
  background-color: #707070;
  color: white;
  width: 300px;
  height: 60px;
  border-radius: 30px;
  margin: auto;
}
.return_button:before {
  content: '';
  position: absolute;
  left: 40px;
  top: 45%;
  width: 8px;
  height: 8px;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  transform: rotate(135deg);
}
.return_button:hover {
  opacity: 80%;
}
.interviewTop {
  background-color: #16214b;
  color: #fff;
  position: absolute;
  font-size: 12px;
  border-radius: 50%;
  right: 50px;
  width: 54px;
  height: 54px;
  padding-top: 21px;
  padding-left: 15px;
}
.interviewTop:hover {
  background-color: #95742e;
}

.customer_voice_underline {
  width: 80px;
  border-width: thin;
  border-bottom: 0px #95742e solid;
  margin: 20px auto 40px auto;
}

.interviewTop::before {
  display: block;
  content: '';
  position: absolute;
  bottom: 50%;
  right: 22px;
  width: 10px;
  height: 10px;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
@media screen and (max-width: 1023px) {
  .interviewTop {
    right: 10px;
  }
}
