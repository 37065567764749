.contact .body {
  margin: auto;
}

.contact .section {
  width: 980px;
  padding: 30px 80px 80px;
  margin: auto;
}
@media screen and (max-width: 1023px) {
  .contact .section {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .contact .section {
    padding: 10px 20px 80px;
  }
}
#contactNavbarPC {
  background-color: #fff;
  top: 0;
  width: 100%;
  height: 80px;
  padding: 12px 20px;
}
#contactNavbarSP {
  background-color: #fff;
  top: 0;
  width: 100%;
  transition: top 0.5s;
  padding: 12px 20px;
}
.contactSwitch {
  position: relative;
  display: inline-block;
  color: #95742e;
  border: solid 1px #95742e;
  height: 48px;
  width: 190px;
  padding: 8px 0 20px 0;
}
@media screen and (max-width: 767px) {
  .contactSwitch {
    width: 130px;
  }
}
.contactSwitch:hover {
  color: #fff;
  background-color: #95742e;
}
.contactSwitch::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 8px;
  right: calc(50% - 6px);
  width: 8px;
  height: 8px;
  border-right: solid 1px #95742e;
  border-bottom: solid 1px #95742e;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.contactSwitch:hover::after {
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
}
.checked.contactSwitch {
  position: relative;
  display: inline-block;
  color: #fff;
  background-color: #95742e;
  height: 48px;
  width: 190px;
  padding: 8px 0 20px 0;
}
.checked.contactSwitch::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 8px;
  right: calc(50% - 6px);
  width: 8px;
  height: 8px;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media screen and (max-width: 767px) {
  .checked.contactSwitch {
    width: 130px;
  }
}
.contactSwitch:hover {
  color: #fff;
  background-color: #95742e;
}
.contactForm {
}
.contactForm label::before {
  content: '必須';
  color: white;
  background-color: #95742e;
  padding: 2px 12px;
  font-size: 14px;
  border-radius: 2px;
  margin-right: 20px;
}
.contactForm label.unrequire::before {
  content: '任意';
  color: white;
  background-color: #7e87a9;
  padding: 2px 12px;
  font-size: 14px;
  border-radius: 2px;
  margin-right: 20px;
}
.contactForm li {
  display: flex;
  background-color: #f0ede6;
  padding: 36px;
  align-items: center;
}
.contactForm li.contentsConfirm {
  align-items: normal;
}
@media screen and (max-width: 767px) {
  .contactForm li {
    display: block;
    padding: 16px;
  }
}
.contactForm li:nth-child(2n) {
  background-color: #f6f4ee;
}
.contactForm li label {
  display: inline-block;
  min-width: 200px;
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .contactForm li label {
    padding-bottom: 16px;
  }
}
.contactForm li p {
  min-width: 200px;
  margin: auto 0px;
}
.contactForm li span {
  min-width: 200px;
  margin-right: 20px;
}
.contactForm input {
  border: solid 1px #b8b3a6;
  padding: 4px 0 4px 12px;
  outline: none;
  margin-right: 10px;
}
.contactForm input::placeholder {
  color: #b8b3a6;
}
.contactForm select {
  background-color: #fff;
  border: solid 1px #b8b3a6;
  height: 34px;
  outline: none;
  margin-right: 10px;
}
.contactForm textarea {
  height: 240px;
  border: solid 1px #b8b3a6;
  padding: 4px 0 4px 12px;
  outline: none;
}

.contactForm textarea::placeholder {
  color: #b8b3a6;
}
.contactSubmit {
  position: relative;
  display: inline-block;
  color: #95742e;
  border: solid 1px #95742e;
  height: 48px;
  width: 320px;
  padding: 10px 0 20px 0;
}
@media screen and (max-width: 767px) {
  .contactSubmit {
    width: 280px;
  }
}
.contactSubmit:hover {
  color: #fff;
  background-color: #95742e;
}
.contactSubmit::after {
  display: block;
  content: '';
  position: absolute;
  bottom: calc(50% - 4px);
  right: 24px;
  width: 8px;
  height: 8px;
  border-right: solid 1px #95742e;
  border-bottom: solid 1px #95742e;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.contactSubmit:hover::after {
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
}
.contactSubmitError {
  border: solid 1px red;
  background-color: #ffe9e9;
}
p.contactSubmitErrorMessage {
  color: red;
}
.contactBackButton {
  width: 320px;
  height: 48px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .contactBackButton {
    width: 280px;
  }
}
.contact .toTop {
  color: #95742e;
}
.contact .toTop:hover {
  color: inherit;
}
