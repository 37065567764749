.LPFooter {
  background-color: #16214b;
  width: 100%;
  height: auto;
  padding-top: 4rem;
  padding-bottom: 1.5rem;
}
.LPFooterLogo {
  width: 12rem;
  margin: 0 auto 5rem;
}
@media screen and (max-width: 767px) {
  .LPFooterLogo {
    width: 10rem;
    margin: 0 auto 3rem;
  }
}
.LPFooterLinkArea {
  display: flex;
  margin: 0 auto 5rem;
  justify-content: center;
  color: white;
}
@media screen and (max-width: 767px) {
  .LPFooterLinkArea {
    display: block;
    width: 83.333333%;
    margin: 0 auto 3rem;
  }
}
.LPFooterLink {
  text-align: center;
  padding: 0 2.5rem;
}
.LPFooterLink a:hover {
  opacity: 50%;
}
@media screen and (max-width: 767px) {
  .LPFooterLink {
    text-align: left;
    padding: 1rem 2.5rem;
  }
}
.LPFooterCopyright {
  text-align: center;
  font-size: small;
  color: white;
}
.column-border {
  @apply relative;
}
.column-border::before {
  content: '';
  @apply w-1 h-8 absolute block border border-white border-t-0 border-b-0 border-r-0 left-0;
  top: -3px;
}
.column-border::after {
  content: '';
  @apply w-1 h-8 absolute block border border-white border-t-0 border-b-0 border-l-0 right-0;
  top: -3px;
}

/* 無理 */
@media screen and (max-width: 767px) {
  .column-border::before {
    content: '';
    @apply w-8 h-1 absolute block border border-white border-l-0 border-b-0 border-r-0;
    left: 38px;
    top: 0;
  }
  .column-border::after {
    content: '';
    @apply w-8 h-1 absolute block border border-white border-r-0 border-t-0 border-l-0;
    left: 38px;
    top: 54px;
  }
}
