@tailwind base;
@tailwind components;
@tailwind utilities;
button:focus {
  outline: none;
}
body {
  color: theme('colors.neutral');
  @apply font-serif;
}
img {
  display: inline;
}
ul {
  list-style-type: none;
}
.top_h2 {
  color: theme('colors.primary');
  font-weight: 600;
  @apply block relative text-4xl text-center leading-loose;
}
.top_h2::after {
  content: '';
  background-color: theme('colors.secondary');
  @apply absolute block w-full h-underLine m-0 mx-auto inset-x-0 bottom-0;
}
.newLP {
  background-size: contain;
}

#navbarPC {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  transition: top 0.3s;
  padding: 12px 20px;
  z-index: 30;
}

#navbarPC .navHover {
  color: #16214b;
  text-align: center;
  position: relative;
  padding: 9px;
}
#navbarPC .navHover::after {
  content: '';
  background-color: #95742e;
  display: block;
  position: absolute;
  bottom: 0;
  right: 50%;
  width: 0%;
  height: 1.5px;
  -webkit-transform: translate(50%, 0);
  transform: translate(50%, 0);
}
#navbarPC .navHover:hover::after {
  color: #95742e;
  width: 100%;
  height: 1.5px;
  transition: 0.2s;
}
#navbarPC button {
  background-color: #95742e;
  color: #fff;
  width: 180px;
  height: 50px;
  border-radius: 30px;
  margin-left: 15px;
}
#navbarPC button:hover {
  background-color: #a88844;
}
#navbarSP {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  transition: top 0.5s;
  z-index: 30;
}
#navbarSP .flex.justify-between {
  padding: 12px 20px;
}

#navbarSP .justify-evenly a {
  display: inline-block;
  position: relative;
  background-color: #fff;
  color: #95742e;
  width: 110px;
  height: 26px;
  border-radius: 30px;
  margin: 12px auto;
  font-size: 11px;
  padding: 4px 0 4px 12px;
}
@media screen and (max-width: 767px) {
  #navbarSP .justify-evenly a {
    width: 100px;
  }
}
#navbarSP .justify-evenly a::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 50%;
  right: 12px;
  width: 6px;
  height: 6px;
  border-right: solid 1px #95742e;
  border-bottom: solid 1px #95742e;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.newLP p {
  color: #16214b;
  font-weight: 600;
  word-break: break-all;
}
.newLP span {
  color: #16214b;
  font-weight: 600;
}
.newLP .text-secondary {
  color: #95742e;
}
.newLP .body {
  margin: -70px auto 0;
  padding-top: 25px;
  z-index: 10;
}

@media screen and (max-width: 767px) {
  .newLP .body {
    padding-top: 85px;
  }
}
.newLP .LP_head {
  bottom: 0;
  z-index: -10;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .newLP .LP_head {
    bottom: 0;
    z-index: -10;
    display: block;
    margin: 0 auto;
  }
}

.newLP .headText {
  position: absolute;
  top: 20%;
  left: calc(50% - 490px);
  font-size: 40px;
  font-weight: 600;
  color: #fff;
}
.newLP .headSubText {
  position: absolute;
  top: 40%;
  left: calc(50% - 490px);
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
@media screen and (max-width: 1024px) {
  .newLP .headText {
    left: 80px;
  }
  .newLP .headSubText {
    left: 80px;
    top: 48%;
  }
}

@media screen and (min-width: 1981px) {
  .newLP .LP_head {
    max-width: 1980px;
  }
}

.newLP .whiteBox {
  position: absolute;
  background-color: white;
  width: 980px;
  height: 65px;
  z-index: 0;
  bottom: -1px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}
@media all and (-ms-high-contrast: none) {
  /* IEではwidth:980pxの影響で崩れるので削除 */
  .newLP .whiteBox {
    display: none;
  }
}
@media screen and (max-width: 1023px) {
  .newLP .whiteBox {
    display: none;
  }
}

.newLP .PC_sample {
  position: absolute;
  bottom: -20px;
  z-index: 10;
  display: block;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 32%;
}
.newLP .SP_sample {
  position: absolute;
  bottom: -35px;
  z-index: 20;
  display: block;
  left: 35%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  width: 8%;
}

.newLP .LP_head_interview {
  position: absolute;
  top: 20%;
  right: calc(45% - 490px);
  z-index: 20;
  display: block;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  /* width: 185px; */
}

.newLP .LP_head_interviewImage {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .newLP .PC_sample {
    bottom: -80px;
    width: 75%;
  }
  .newLP .SP_sample {
    left: 15%;
    bottom: -95px;
    width: 20%;
  }
  .newLP .LP_head_interview {
    right: -10%;
    bottom: 20%;
    top: initial;
    /* width: 130px; */
  }
}

@media screen and (max-width: 1024px) {
  .newLP .LP_head_interview {
    right: -40px;
  }
}

@media screen and (min-width: 1981px) {
  .newLP .PC_sample {
    width: calc(1980px * 0.32);
  }
  .newLP .SP_sample {
    left: calc(50% - 300px);
    width: calc(1980px * 0.08);
  }
}

.newLP .section {
  width: 980px;
  padding: 100px 80px 80px;
  margin: auto;
}

.section-interview {
  width: 980px;
  padding: 50px 0px;
  margin: auto;
}

.section-interview-bottom {
  width: 980px;
  padding: 0px 80px 80px;
  margin: auto;
}

@media screen and (max-width: 1023px) {
  .newLP .section,
  .section-interview,
  .section-interview-bottom {
    width: auto;
  }
}
@media screen and (max-width: 767px) {
  .newLP .section,
  .section-interview {
    padding: 100px 20px 80px;
  }
  .section-interview-bottom {
    padding: 0px 20px 80px;
  }
}

@media screen and (max-width: 500px) {
  .newLP .section,
  .section-interview {
    padding: 100px 10px 80px;
  }
}

.newLP .about {
  width: 517px;
  margin: 0 auto 35px;
  font-size: 18px;
}
.newLP .aboutImage {
  width: 820px;
}
@media screen and (max-width: 767px) {
  .newLP .about {
    width: auto;
    font-size: 13px;
  }
  .newLP .aboutImage {
    width: auto;
  }
}
.newLP .caption {
  font-size: 30px;
  line-height: 50px;
  text-align: center;
  color: #16214b;
  font-weight: bold;
}
@media screen and (max-width: 767px) {
  .newLP .caption {
    font-size: 26px;
  }
}
.newLP .subCaption {
  font-size: 14px;
  line-height: 50px;
  text-align: center;
  color: #95742e;
}
.newLP .refer {
  background-color: #ebebf1;
  padding: 16px 32px;
  margin: 20px auto 40px;
}
.newLP .referCaption {
  position: relative;
  display: inline-block;
}
.newLP .referCaption::before {
  content: '';
  position: absolute;
  bottom: 50%;
  left: -16px;
  width: 18px;
  border-top: solid 1px #16214b;
  -webkit-transform: rotate(75deg);
  transform: rotate(75deg);
}
.newLP .referCaption::after {
  content: '';
  position: absolute;
  bottom: 50%;
  right: -16px;
  width: 18px;
  border-top: solid 1px #16214b;
  -webkit-transform: rotate(105deg);
  transform: rotate(105deg);
}
.newLP .topLineShape::before {
  content: '';
  position: absolute;
  width: 175px;
  height: 50px;
  border-bottom: solid 1px #16214b;
  border-right: solid 1px #16214b;
  right: 50%;
  bottom: -60px;
  border-radius: 0 0 40px 0;
}

.newLP .midLineShape::before {
  content: '';
  position: absolute;
  width: 175px;
  height: 105px;
  border-bottom: solid 1px #16214b;
  border-right: solid 1px #16214b;
  right: 50%;
  bottom: -115px;
  border-radius: 0 0 40px 0;
}

.newLP .bottomLineShape::before {
  content: '';
  position: absolute;
  width: 175px;
  height: 105px;
  border-bottom: solid 1px #16214b;
  border-right: solid 1px #16214b;
  right: 50%;
  bottom: -170px;
  border-radius: 0 0 40px 0;
}

.newLP .topLineShape::after {
  content: '';
  position: absolute;
  width: 175px;
  height: 50px;
  border-bottom: solid 1px #16214b;
  border-left: solid 1px #16214b;
  left: 50%;
  bottom: -60px;
  border-radius: 0 0 0 40px;
}

.newLP .midLineShape::after {
  content: '';
  position: absolute;
  width: 175px;
  height: 105px;
  border-bottom: solid 1px #16214b;
  border-left: solid 1px #16214b;
  left: 50%;
  bottom: -115px;
  border-radius: 0 0 0 40px;
}

.newLP .bottomLineShape::after {
  content: '';
  position: absolute;
  width: 175px;
  height: 105px;
  border-bottom: solid 1px #16214b;
  border-left: solid 1px #16214b;
  left: 50%;
  bottom: -170px;
  border-radius: 0 0 0 40px;
}

.newLP .useScene {
  margin: 20px auto 60px;
}

.newLP .useScene .decorationLine::before {
  content: '';
  position: absolute;
  height: 1px;
  width: 60px;
  border-bottom: solid 1px #16214b;
  top: 50%;
  left: -80px;
}
.newLP .useScene .decorationLine::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 60px;
  border-bottom: solid 1px #16214b;
  top: 50%;
  right: -80px;
}
@media screen and (max-width: 1023px) {
  .newLP .useScene .decorationLine::before {
    display: none;
  }
  .newLP .useScene .decorationLine::after {
    display: none;
  }
}
.newLP .useScene .case {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  padding: 24px 16px 0;
  display: flex;
  flex-direction: column;
}
@media screen and (max-width: 1023px) {
  .newLP .useScene .case {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    padding: 12px 8px 0;
    display: inline-flex;
    flex-direction: column;
  }
}
.newLP .useScene .case .head {
  border-bottom: solid white 1px;
  font-size: 24px;
  padding-bottom: 4px;
  vertical-align: bottom;
}
@media screen and (max-width: 1023px) {
  .newLP .useScene .case .head {
    border-bottom: solid white 1px;
    font-size: 20px;
    padding-bottom: 4px;
    vertical-align: bottom;
  }
}

.newLP .useScene p {
  color: white;
  font-weight: 400;
}
.newLP .interviewBanner {
  background-color: #16214b;
  width: 500px;
  min-height: 170px;
  padding: 18px;
}

.newLP .interviewImage {
  width: 160px;
}

.newLP .interviewBanner p {
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}
.newLP .interviewBanner .normalText {
  font-size: 14px;
}
.newLP .interviewBanner .smallText {
  font-size: 12px;
}

.newLP .interviewBanner .interviewLink {
  border: solid 1px #fff;
  padding: 4px 16px 0;
  height: 32px;
  text-align: center;
  min-width: 200px;
  vertical-align: middle;
}
.newLP .interviewBanner .interviewLink:hover {
  background-color: #fff;
}
.newLP .interviewBanner p.interviewLink {
  color: #fff;
  font-size: 14px;
}
.newLP .interviewBanner p.interviewLink:hover {
  cursor: pointer;
  color: #16214b;
}
.newLP .interviewBanner p.interviewLink:after {
  display: block;
  content: '';
  position: absolute;
  right: 20px;
  top: 35%;
  width: 8px;
  height: 8px;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  transform: rotate(-45deg);
}
.newLP .interviewBanner p.interviewLink:hover:after {
  border-color: #16214b;
}
.newLP .interviewBanner .interviewLink .linkImg {
  position: absolute;
  margin-top: 4px;
  right: 16px;
}
.newLP .interviewBanner .interviewLink:hover .linkImg {
  display: none;
}

.newLP .interviewBanner .interviewLink .hoverImg {
  display: none;
}
.newLP .interviewBanner .interviewLink:hover .hoverImg {
  display: inline;
  position: absolute;
  margin-top: 4px;
  right: 16px;
}

.newLP .interviewImage {
  width: 160px;
}
@media screen and (max-width: 767px) {
  .newLP .interviewBanner {
    width: 100%;
    max-width: 355px;
    padding: 24px;
  }
  .newLP .interviewBanner .interviewLink {
    width: 100%;
  }
  .newLP .interviewImage {
    width: 195px;
  }
}

.newLP .youtubeArea {
  background-color: #16214b;
  padding: 24px 30px;
  margin-top: 20px;
}

.newLP .youtubeArea p {
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}
.newLP .youtubeArea .youtubeLink {
  border: solid 1px #fff;
  padding: 4px 16px 0;
  height: 32px;
  text-align: center;
  vertical-align: middle;
}
.newLP .youtubeArea .youtubeLink:hover {
  background-color: #fff;
}
.newLP .youtubeArea p.youtubeLink {
  color: #fff;
  font-size: 14px;
}
.newLP .youtubeArea p.youtubeLink:hover {
  color: #16214b;
}
.newLP .youtubeArea .youtubeLink .linkImg {
  position: absolute;
  margin-top: 4px;
  right: 16px;
}
.newLP .youtubeArea .youtubeLink:hover .linkImg {
  display: none;
}

.newLP .youtubeArea .youtubeLink .hoverImg {
  display: none;
}
.newLP .youtubeArea .youtubeLink:hover .hoverImg {
  display: inline;
  position: absolute;
  margin-top: 4px;
  right: 16px;
}

.newLP .youtubeImg {
  width: auto;
  height: 110px;
}
@media screen and (max-width: 767px) {
  .newLP .youtubeArea {
    width: 100%;
    max-width: 355px;
    padding: 24px 16px;
    text-align: center;
    margin: 10px auto;
  }
  .newLP .youtubeArea p {
    font-size: 20px;
  }
  .newLP .youtubeArea .youtubeLink {
    width: 100%;
  }
}
.newLP .gridItem {
  width: 100%;
  padding: 0 1rem;
  word-break: keep-all;
}
@media screen and (max-width: 767px) {
  .newLP .gridItem {
    width: auto;
    word-break: keep-all;
  }
}
.newLP .top_h2 {
  margin-top: 35px;
  font-size: 22px;
  line-height: 50px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .newLP .top_h2 {
    border: none;
  }
  .newLP .top_h2::after {
    border-bottom: solid 1px #95742e;
    width: 50px;
    height: 1px;
  }
}
.newLP h2 .voiceNumber {
  position: absolute;
  right: 0;
  bottom: -20px;
  height: 114px;
}

@media screen and (max-width: 767px) {
  .newLP h2 .voiceNumber {
    position: absolute;
    right: auto;
    left: 0;
    bottom: -20px;
    height: 114px;
  }
}

.userVoice {
  position: relative;
  width: 100%;
  border-color: #95742e;
  border-width: 1px;
  border-radius: 8px;
  padding: 35px 32px 35px 45px;
  z-index: 1;
  background-color: white;
}
.userVoice span {
  font-weight: normal;
}
.userVoiceImg {
  width: auto;
  height: 215px;
}
.userVoice.odd::before {
  content: '';
  position: absolute;
  right: -25px;
  width: 25px;
  height: 20px;
  bottom: 40px;
  border-left: solid 1px #fff;
  border-top: solid 1px #95742e;
}
.userVoice.odd::after {
  content: '';
  position: absolute;
  right: -28px;
  width: 32px;
  bottom: 49px;
  border-bottom: solid 1px #95742e;
  transform: rotate(-38deg);
  z-index: 0;
}
.userVoice.even::before {
  content: '';
  position: absolute;
  left: -25px;
  width: 25px;
  height: 20px;
  bottom: 40px;
  border-right: solid 1px #fff;
  border-top: solid 1px #95742e;
}
.userVoice.even::after {
  content: '';
  position: absolute;
  left: -28px;
  width: 32px;
  bottom: 49px;
  border-bottom: solid 1px #95742e;
  transform: rotate(38deg);
  z-index: 0;
}
@media screen and (max-width: 767px) {
  .userVoice {
    padding: 35px 36px 35px 24px;
    margin-bottom: 36px;
  }
  .userVoiceImg {
    width: 100%;
    height: auto;
  }
  .userVoice.odd::before {
    bottom: -28px;
    width: 20px;
    height: 28px;
    left: 72px;
    border-right: solid 1px #95742e;
    border-top: solid 1px #fff;
  }
  .userVoice.odd::after {
    bottom: -14px;
    width: 32px;
    left: 66px;
    border-left: solid 1px #95742e;
    transform: rotate(56deg);
    z-index: 0;
  }
  .userVoice.even::before {
    bottom: -28px;
    width: 20px;
    height: 28px;
    left: 72px;
    border-right: solid 1px #95742e;
    border-top: solid 1px #fff;
  }
  .userVoice.even::after {
    bottom: -14px;
    width: 32px;
    left: 66px;
    border-left: solid 1px #95742e;
    transform: rotate(56deg);
    z-index: 0;
  }
}
.newLP .contact {
  background-color: #f8f4e9;
  padding: 45px 0;
  text-align: center;
}
.newLP .contact button {
  background-color: #95742e;
  color: white;
  width: 300px;
  height: 60px;
  border-radius: 30px;
  margin: auto;
}
.newLP .contact button:hover {
  background-color: #a88844;
}
.faqLink {
  position: relative;
  display: inline-block;
  color: #95742e;
  border-radius: 4px;
  border: solid 1px #95742e;
  height: 48px;
  width: 190px;
  padding: 10px 16px 20px 0;
}
@media screen and (max-width: 767px) {
  .faqLink {
    display: block;
  }
}
.faqLink:hover {
  color: #fff;
  background-color: #95742e;
}
.faqLink::after {
  display: block;
  content: '';
  position: absolute;
  bottom: 50%;
  right: 20px;
  width: 8px;
  height: 8px;
  border-right: solid 1px #95742e;
  border-bottom: solid 1px #95742e;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.faqLink:hover::after {
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
}
@media screen and (max-width: 767px) {
  .faqLink {
    margin: 0 auto 20px;
    color: #fff;
    background-color: #95742e;
    width: auto;
  }
  .faqLink::after {
    border-right: solid 1px #fff;
    border-bottom: solid 1px #fff;
  }
}
.faqCaption {
  position: relative;
  font-size: 26px;
  text-align: center;
  padding-top: 50px;
}
.faqCaption::after {
  content: '';
  position: absolute;
  border-bottom: solid 1px #95742e;
  bottom: -20px;
  right: calc(50% - 25px);
  width: 50px;
  height: 1px;
}
.representative_QandA p {
  font-weight: normal;
  @apply font-sans;
}
.representative_Q {
  position: relative;
  padding: 16px 0;
  border-bottom: solid 1px #95742e;
}
.representative_Q::before {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  width: 16px;
  border-bottom: solid 1px #16214b;
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  .representative_Q::before {
    width: 24px;
  }
}

/* link */
.link {
  color: #95742e;
}

.link:hover {
  text-decoration: underline;
}

.representative_Q::after {
  display: block;
  content: '';
  position: absolute;
  top: 50%;
  right: 20px;
  width: 16px;
  border-bottom: solid 1px #16214b;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: 0.5s;
}
@media screen and (max-width: 767px) {
  .representative_Q::after {
    width: 24px;
  }
}
.faq-check {
  display: none;
}
.notOpen {
  display: block;
  position: relative;
}
.representative_A {
  opacity: 0;
  display: none;
}
.newLP .representative_A p {
  color: #95742e;
}
.open .representative_Q::after {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s;
}
.open .representative_A {
  display: flex;
  opacity: 1;
  padding: 16px 0;
  color: #95742e;
}

@keyframes openRepresentative_A {
  0% {
    opacity: 0;
  }
  100% {
    display: inline-block;
    opacity: 1;
  }
}

@keyframes closeRepresentative_A {
  0% {
    display: inline-block;
    opacity: 1;
  }
  100% {
    display: inline-block;
    opacity: 0;
  }
}
button.toTop {
  background-color: #16214b;
  color: #fff;
  position: absolute;
  font-size: 12px;
  border-radius: 50%;
  bottom: -15px;
  right: -27px;
  width: 54px;
  height: 54px;
  padding-top: 14px;
}
button.toTop:hover {
  background-color: #95742e;
}
button.toTop::before {
  display: block;
  content: '';
  position: absolute;
  bottom: 50%;
  right: 22px;
  width: 10px;
  height: 10px;
  border-right: solid 1px #fff;
  border-bottom: solid 1px #fff;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
@media screen and (max-width: 1023px) {
  button.toTop {
    right: 10px;
  }
}

.circleLPInterview-banner {
  background: #95742e;
  border-radius: 3px;
  padding: 2px 12px;
  color: white;
}
.circleLPInterview-banner-container {
  position: relative;
  display: inline-flex;
  font-size: 14px;
  top: 26px;
  text-align: center;
}

.circleLPInterview-up-text {
  margin: auto;
  word-break: keep-all;
  position: relative;
  font-size: 20px;
  color: white;
  top: 6px;
}
.circleLPInterview-down-text {
  /* width: 100px; */
  margin: auto;
  word-break: keep-all;
  position: relative;
  font-size: 20px;
  color: black;
  top: 16px;
  line-height: 25px;
}

.circleLPInterview-circle {
  width: 180px;
  height: 180px;
  background: white;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.circleLPInterview-circleUp {
  width: 180px;
  height: 90px;
  border: 3px solid white;
  background: #16214b;
  text-align: center;
  border-radius: 100px 100px 0px 0px;
}

.introducebanner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 360px;
  margin: 0 auto;
}

.circleLPInterview-down-text pre {
  min-height: 50px;
  margin: 0px;
  font-family: Noto Serif JP, serif;
}

.circleLPInterview-up-text pre {
  min-height: 60px;
  margin: 0px;
  font-family: Noto Serif JP, serif;
}

.introducebanner pre {
  font-family: Noto Serif JP, serif;
  word-wrap: break-word;
  white-space: pre-wrap;
  color: #fff;
  font-size: 22px;
  font-weight: bold;
}

.circleLPInterview-part-color {
  position: absolute;
  bottom: 51%;
  width: 177px;
  height: 12px;
  border-radius: 3px 3px 0px 0px;
  text-align: center;
  background: white;
  margin-left: 2px;
}

@media screen and (max-width: 767px) {
  .circleLPInterview-part-color {
    position: absolute;
    bottom: 51%;
    width: 117px;
    height: 6px;
    border-radius: 3px 3px 0px 0px;
    text-align: center;
    background: white;
  }

  .circleLPInterview-banner {
    background: #95742e;
    border-radius: 3px;
    padding: 2px 6px;
    color: white;
  }
  .circleLPInterview-banner-container {
    position: relative;
    display: inline-flex;
    font-size: 10px;
    top: 5px;
    text-align: center;
  }

  .circleLPInterview-up-text {
    margin: auto;
    word-break: keep-all;
    position: relative;
    font-size: 14px;
    color: white;
    top: 5px;
  }
  .circleLPInterview-down-text {
    width: 50px;
    margin: auto;
    word-break: keep-all;
    position: relative;
    font-size: 14px;
    color: black;
    line-height: 18px;
    top: 7px;
  }

  .circleLPInterview-circle {
    width: 120px;
    height: 120px;
    background: white;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  }

  .circleLPInterview-circleUp {
    width: 120px;
    height: 60px;
    border: 3px solid white;
    background: #16214b;
    text-align: center;
    border-radius: 60px 60px 0px 0px;
  }

  .circleLPInterview-up-text pre {
    min-height: 0px;
    margin: 0px;
    font-family: Noto Serif JP, serif;
  }

  .circleLPInterview-down-text pre {
    min-height: 0px;
    margin: 0px;
    font-family: Noto Serif JP, serif;
  }
}
.main-intro {
  min-height: 100px;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sougi-overlay-inner {
  position: relative;
  width: 100%;
}

.sougi-intro-grid-container {
  position: absolute;
  width: 100%;
  text-align: center;
}

.sougi-overlay-container {
  display: flex;
  height: 850px;
  justify-content: center;
  color: #16214b;
}

.sougi-intro-overlay-title {
  margin: 0px 40px 10px 40px;
  padding: 10px 0px;
  color: #94742e;
  border: solid #94742e 1px;
  color: #94742e;
  background: white;
  width: calc(100% - 80px);
}

.lp-intro-checkIcon {
  margin-top: 4px;
}

@media screen and (max-width: 900px) {
  .sougi-overlay-container {
    height: 980px;
  }
}

@media screen and (max-width: 768px) {
  .main-intro {
    min-height: 40px;
  }
  .sougi-overlay-container {
    height: 960px;
  }
}

@media screen and (max-width: 500px) {
  .sougi-overlay-container {
    height: 1100px;
  }
}
@media screen and (max-width: 425px) {
  .sougi-overlay-container {
    height: 1200px;
  }
  .sougi-intro-overlay-title {
    margin: 10px;
    width: calc(100% - 20px);
  }
}
