.box {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.85;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: theme('zIndex.blockUI');
}
.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 15vh;
  height: 15vh;
  border-radius: 50%;
  border-width: 11px;
  border-style: solid;
  border-color: #bfac82 rgba(191, 172, 130, 0.42) rgba(191, 172, 130, 0.42);
  -webkit-animation: spinner1_1 1.5s infinite linear forwards;
  animation: spinner1_1 1.5s infinite linear forwards;
}

@-webkit-keyframes spinner1_1 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spinner1_1 {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0);
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
